<template>
  <v-app>
     <router-view />

       <v-snackbar
      v-model="snackbar"
      :multi-line="true"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
  export default {
    name: 'Blanklayout',

    components: {
      
    },
    data: () => ({
    message: '',
    snackbar: false
  }),
  computed: {
   snackAttack() {
      return this.$store.state.snack.attack;
    }
  },
  watch: {
    snackAttack: function(newValue) {
      if (newValue) {      
        this.message = newValue;
        this.snackbar = true;
        this.$store.commit("snack/set_snack", "");
      }
    }
  },
    created: function() {
       const msg = this.$store.state.snack.attack;
      if (msg !== '') {
        this.snackbar = true
        this.message = this.$store.state.snack.attack
        this.$store.commit('snack/set_snack', '')
      }
    }
  }
</script>
